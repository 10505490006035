<!--
 * @Description: 数据中心 > 物业报表 > 访客记录--列表
 * @Author: 小广
 * @Date: 2019-07-04 15:42:41
 * @LastEditors  : 蒙川
 * @LastEditTime : 2019-12-24 18:09:39
 -->

<template>
  <div class="VisitorRecordList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn="false"
    >
      <template #searchSlot>
        <v-datepicker
          label="进入时间段"
          defaultTimeType="week"
          :startTime.sync="searchParams.enterDateStart"
          :endTime.sync="searchParams.enterDateEnd">
        </v-datepicker>
        <v-input label="用户姓名" v-model="searchParams.ownerName" placeholder="请输入完整姓名"></v-input>
        <v-input label="访客姓名" v-model="searchParams.visitorName" placeholder="请输入完整姓名"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select label="访客类型" v-model="searchParams.visitorType" :options="visitorTypeOps"></v-select>
        <v-select label="访客目的" v-model="searchParams.reason" :options="reasonOps"></v-select>
        <v-datepicker
          label="离开时间段"
          :startTime.sync="searchParams.leaveDateStart"
          :endTime.sync="searchParams.leaveDateEnd">
        </v-datepicker>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionParams"></v-select2>
        <v-select label="进入方式" v-model="searchParams.enterType" :options="enterTypeOps"></v-select>
        <v-select label="访客状态" v-model="searchParams.visitorStatus" :options="visitorStatusOps"></v-select>
        <v-select label="数据来源" v-model="searchParams.sourceType" :options="sourceTypeOps"></v-select>
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams, regionParams } from 'common/select2Params'
import { getListURL, exportListURL, queryResonURL } from './api'
import {
  visitorTypeOps,
  visitorStatusMap,
  visitorStatusOps,
  reasonOps,
  sourceTypeMap,
  sourceTypeOps
} from './map'

export default {
  name: 'VisitorRecordList',
  data () {
    let _this = this
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      visitorTypeOps: visitorTypeOps(1),
      enterTypeOps: [],
      enterTypeMap: {},
      visitorStatusOps: visitorStatusOps(1),
      reasonOps: [],
      sourceTypeOps: sourceTypeOps(1),
      communityParams: communityParams,
      regionParams: regionParams,
      searchParams: {
        enterDateStart: '',
        enterDateEnd: '',
        ownerName: '',
        visitorName: '',
        communityId: '',
        visitorType: undefined,
        reason: undefined,
        leaveDateStart: '',
        leaveDateEnd: '',
        regionId: '',
        enterType: undefined,
        visitorStatus: undefined,
        sourceType: undefined
      },
      headers: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'ownerAddress',
          label: '拜访房号'
        },
        {
          prop: 'ownerName',
          label: '拜访用户'
        },
        {
          prop: 'ownerMobile',
          label: '用户手机号'
        },
        {
          prop: 'sourceTypeText',
          label: '数据来源',
          formatter (row) {
            return sourceTypeMap[row.sourceType]
          }
        },
        {
          prop: 'visitorName',
          label: '访客姓名'
        },
        {
          prop: 'visitorMobile',
          label: '访客手机号'
        },
        {
          prop: 'visitorIDNo',
          label: '访客身份证号'
        },
        {
          prop: 'visitorCar',
          label: '访客车辆'
        },
        {
          prop: 'enterTypeText',
          label: '进入方式',
          formatter (row) {
            return _this.enterTypeMap[row.enterType]
          }
        },
        {
          prop: 'visitorPurpose',
          label: '访客目的',
          show: this.getShowReason()
        },
        {
          prop: 'visitorStatusText',
          label: '访客状态',
          formatter (row) {
            return visitorStatusMap[row.visitorStatus]
          }
        },
        {
          prop: 'arriveTime',
          label: '进入时间'
        },
        {
          prop: 'leaveTime',
          label: '离开时间'
        },
        {
          prop: 'companyName',
          label: '所属公司'
        },
        {
          prop: 'remark',
          label: '备注'
        }
      ]
    }
  },
  async created () {
    await this.$store.dispatch('mapStore/queryVisitorEnterType')
    const { setVisitorEnterTypeOps, visitorEnterTypeMap } = this.$store.getters['mapStore/getVisitorEnterType']()
    this.enterTypeOps = setVisitorEnterTypeOps(1)
    this.enterTypeMap = visitorEnterTypeMap
  },
  mounted () {
    this.queryReasonOps()
  },
  methods: {
    // 根据账户角色权限，获取是否要显示访客目的
    getShowReason () {
      let userInfo = this.$store.state.userInfo
      let show = false
      if (userInfo) {
        if (userInfo.userType && (userInfo.userType === '100' || userInfo.userType === '106')) {
          show = true
        }
      }
      return show
    },
    async queryReasonOps () {
      let { data } = await this.$axios.get(queryResonURL)
      this.reasonOps = data.map(text => ({ value: text, text }))
      this.reasonOps.unshift({
        value: undefined,
        text: '全部'
      })
    }
  }
}
</script>

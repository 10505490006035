// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}visitor/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}visitor/data/export`
// 查询访客目的
const queryResonURL = `${API_CONFIG.butlerBaseURL}visitor/reasonNameList`

export {
  getListURL,
  exportListURL,
  queryResonURL
}

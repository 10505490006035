import { mapHelper } from 'common/utils'

// 访客类型
const visitorType = [
  {
    text: '车行',
    value: 2
  },
  {
    text: '人行',
    value: 1
  }
]

const {
  map: visitorTypeMap,
  setOps: visitorTypeOps
} = mapHelper.setMap(visitorType)

// 访客状态
const visitorStatus = [
  {
    text: '未入园',
    value: 0
  },
  {
    text: '未离园',
    value: 1
  },
  {
    text: '已离园',
    value: 2
  }
]

const {
  map: visitorStatusMap,
  setOps: visitorStatusOps
} = mapHelper.setMap(visitorStatus)

// 访客目的
const reason = [
  {
    text: '访友',
    value: '访友'
  },
  {
    text: '装修',
    value: '装修'
  },
  {
    text: '送货',
    value: '送货'
  },
  {
    text: '看房',
    value: '看房'
  },
  {
    text: '其他',
    value: '其他'
  }
]

const {
  setOps: reasonOps
} = mapHelper.setMap(reason)

// 数据来源
const sourceType = [
  {
    text: '用户端App',
    value: 0
  },
  {
    text: '微信小程序',
    value: 8
  },
  {
    text: '支付宝小程序',
    value: 3
  }
]

const {
  map: sourceTypeMap,
  setOps: sourceTypeOps
} = mapHelper.setMap(sourceType)

export {
  visitorTypeMap,
  visitorTypeOps,
  visitorStatusMap,
  visitorStatusOps,
  reasonOps,
  sourceTypeMap,
  sourceTypeOps
}
